import _ from 'lodash';
import "@accessible360/accessible-slick";
import Vivus from "vivus";
import { CountUp } from 'countup.js';
import AOS from "aos";
//import 'aos/dist/aos.css';
import "../styles/main.scss";
import "./header.js";
import "./navigation.js";
import "./search";
import "./search-projects";
import "./search-media.js";
import "./search-learning.js";
import "./projectFilter.js";
import "./accessible_slideshow.js";
import "./lazysizes.min.js";
import "./fslightbox.js";
import simpleParallax from 'simple-parallax-js';


//console.log('Javascript is loading');
// alert('test');


const vivusServices = document.getElementById('animate-trace--services');
if(vivusServices){
    new Vivus('animate-trace--services', {duration: 100, delay: 10}, function(){});
}

const vivusFacilities = document.getElementById('animate-trace--services');
if (vivusFacilities){
    new Vivus('animate-trace--facilities', {type: 'delayed', duration: 100}, function(){});
}






/* ------------------------------ */
/* ----------- MODALS ---------- */
/* ----------------------------- */

// Initialize modalPArent and modal
let originalModalParent;
const modal = document.getElementById('modal-container')

// On modal open
modal.addEventListener('show.bs.modal', event => {
    const button = event.relatedTarget

    // Get inner content of modal
    const modalContent = button.parentElement.querySelector('.js-modal')

    // Get global modal container
    const globalModalContainer = document.querySelector('.modal-container__inner')

    // Set original modal parent for restoring modal back into its original container
    originalModalParent = modalContent.parentElement;

    // const clonedModal = modalContent.cloneNode(true)
    modal.querySelector('.modal-dialog').classList.add(modalContent.querySelector('.modal__inner').getAttribute('data-classes'))

    // Append modal content to global container
    globalModalContainer.appendChild(modalContent);

    //
    // if (modal.querySelector('form').length){
    //     initFormRules(modal.querySelector('form'));
    // }

});

// On modal close
modal.addEventListener('hidden.bs.modal', event => {
    // Get inner modal content and restore back to original parent
     const modalContent = modal.querySelector('.js-modal');
     originalModalParent.appendChild(modalContent);
});


// Check if form in modal has errors. If so, auto-pop previous form modal
const formErrors = document.querySelector('.js-modal .freeform-errors');
if(formErrors){
    const formModal = formErrors.closest('.hidden-modal');
    const formButton = formModal.previousElementSibling;
    // Simulate a click on the form's original button
    formButton.click();
}


//
// function initFormRules(form){
//     // Check for 'Quebec' selected in province/state field. If selected, show language field for 'French' language capture
//     var provinceStateSelect = form.querySelector('select[name=provinceState]');
//     provinceStateSelect.onchange = (event) => {
//         var selectedOption = event.target.value;
//         var languageFieldParent = form.querySelector('select[name=language]').parentElement;
//         if (selectedOption == 'Quebec'){
//             languageFieldParent.style.display = 'block';
//         } else{
//             languageFieldParent.style.display = 'none';
//         }
//     }
//
//     var areaOfInterestSelect = form.querySelector('select[name=areaOfInterest]');
//     areaOfInterestSelect.onchange = (event) => {
//         var selectedOption = event.target.value;
//         var languageFieldParent = form.querySelector('.js-form-careers-cta').parentElement;
//         if (selectedOption == 'Careers'){
//             languageFieldParent.style.display = 'block';
//         } else{
//             languageFieldParent.style.display = 'none';
//         }
//     }
//
// }



// hide modal
// modal.addEventListener('hidden.bs.modal', event => {
//     const modalParent = document.querySelector('.modal-container__inner')
//     while (modalParent.childNodes.length > 0) {
//         modalParent.removeChild(modalParent.childNodes[0]);
//     }
// })



/* ----------------------------- */
/* ------- ACCESSIBILITY ------ */
/* --------------------------- */

// Let the document know when the mouse is being used
document.body.addEventListener('mousedown', function() {
    document.body.classList.add('using-mouse');
});

// Re-enable focus styling when Tab is pressed
document.body.addEventListener('keydown', function(event) {
    if (event.key === 'Tab') {
        document.body.classList.remove('using-mouse');
    }
});


/* ----------------------------- */
/* ------- SCROLL EVENTS ------ */
/* --------------------------- */


var scrollThrottleTimeout;
$(window).bind(
    'scroll',
    function()
    {
        if (!scrollThrottleTimeout) {
            scrollThrottleTimeout = setTimeout(
                function()
                {
                    onPageScroll();
                    scrollThrottleTimeout = null;
                },
                100
            );
        }
    }
);

var prevScrollpos = window.pageYOffset;


function onPageScroll(){
    if ($(window).scrollTop() >= 10) {
        //Dont add the class .is-compressing if in mobile menu, it causes unusual width issues
        if (!$('.header').hasClass('mobile-menu-is-open') && !$('.header').hasClass('is-compressed')){
            $('.header').addClass('is-compressing');
            setTimeout(()=>{
                $('.header').removeClass('is-compressing');
                $('.header').addClass('is-compressed');
            }, 200)
        }
    } else {
        if ($('.header').hasClass('is-compressed')){
            $('.header').addClass('is-decompressing');
            setTimeout(()=>{
                $('.header').removeClass('is-decompressing');
                $('.header').removeClass('is-compressed');
            }, 200)
        }
    }

    if ($(window).scrollTop() > 200 || $(window).scrollTop() > 200) {
        document.getElementById("js-scroll-to-top").classList.add("scroll-to-top-btn--active");
    } else {
        document.getElementById("js-scroll-to-top").classList.remove("scroll-to-top-btn--active");
    }

}

let header = document.querySelector('.header');
if (header) {
    onPageScroll();
}

$("[scroll-to]").click(function (){
    var scrollTarget = $(this).attr('scroll-to');
    $('html, body').animate({
        scrollTop: $('#'+scrollTarget).offset().top - 150
    }, 1000);
});

/* ----------------------------- */
/* ------- VIDEO EVENTS ------ */
/* --------------------------- */

// $('.cap-solution-card').hover(function(){
//     $(this).find('.button').addClass('js-hover');
//     $(this).find('video')[0].play();
// }, function(){
//     $(this).find('.button').removeClass('js-hover');
//     $(this).find('video')[0].pause();
// });

var capSolutionVideos = document.querySelector('.cap-solution-cards');
if (capSolutionVideos) {
    if ($(window).width() < 550) {
        $('.cap-solution-card').each(function() {
            $( this ).find('video')[0].play();
        });
    } else if ($(window).width() > 550) {
        $('.cap-solution-card').hover(function(){
            $(this).find('.button').addClass('js-hover');
            $(this).find('video')[0].play();
        }, function(){
            $(this).find('.button').removeClass('js-hover');
            $(this).find('video')[0].pause();
        });
    }
}


var heroVideo = document.querySelector('.js-hero-video');
if (heroVideo){

    var $video = $('video'),
        videoElement = $video[0],
        videoSource = $video.find('source');

    if ($(window).width() > 550) {
        $video.attr('src', $video.attr('video-src'));
    } else {
        $video.attr('src', $video.attr('video-src').replace('/hero/', '/card/'))
    }

}




/* ------------------------------- */
/* --------IMAGE CAROUSEL--------- */
/* ------------------------------- */
$('.js-image-carousel-block, .image-carousel').slick({dots: true});

// $('.sidebar-panel-project-photos__container').slick({
//     infinite: false,
//     slidesToShow: 4,
// })

/* ------------------------------- */
/* SIDEBAR - DOWNLOAD BLOCK SLIDER */
/* ------------------------------- */
$('.sidebar-related-downloads').slick({
    dots: true,
    slidesToShow: 1,
    adaptiveHeight: true
});

/* ------------------------------- */
/* SIDEBAR - CONTACT SLIDER */
/* ------------------------------- */
$('.js-sidebar-contacts').slick({
    dots: false,
    slidesToShow: 1,
    adaptiveHeight: true
});


/* ------------------------------- */
/* -------- HOME SLIDERS --------- */
/* ------------------------------- */

if ($('.home-explore__slider')[0]){

    $('.home-explore__slider').slick({
        speed: 500,
        fade: true,
        cssEase: 'linear',
        infinite: false,
        nextArrow: $('.home-explore__slider-controls-right'),
        prevArrow: $('.home-explore__slider-controls-left'),
        dots: true,
        appendDots: $('.home-explore__slider-dots'),
        customPaging : function(slider, i) {
            return `<button class="home-explore__slider-dot" data-aos="ki-fade-up-small" data-aos-delay="${(i + 1) * 100}"><span class="visually-hidden">Slide ${i}</span></button>`;
        },
    });

    $('.home-explore__slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
        slick.$slides[currentSlide].querySelector('.explore-slide').classList.add('slide-out');
        slick.$slides[nextSlide].querySelector('.explore-slide').classList.add('slide-in');
        slick.$slides[currentSlide].querySelector('.explore-slide').classList.remove('explore-show-content');
    });

    $('.home-explore__slider').on('afterChange', function(event, slick, currentSlide, nextSlide){
        slick.$slides[currentSlide].querySelector('.explore-slide').classList.add('explore-show-content');
        $('.explore-slide').each(function(){
            $(this).removeClass('slide-out');
            $(this).removeClass('slide-in');
        })
    });

    //initial slide starts off with text visible
    $('.explore-slide')[0].classList.add('explore-show-content');
}



if ($('.home-facilities-slider')[0]){

    $('.home-facilities-slider__slider').slick({
        speed: 500,
        fade: true,
        cssEase: 'linear',
        infinite: false,
        nextArrow: $('.home-facilities-slider__slider-controls-right'),
        prevArrow: $('.home-facilities-slider__slider-controls-left'),
        dots: true,
        appendDots: $('.home-facilities-slider__slider-dots'),
        customPaging : function(slider, i) {
            return `<button class="home-facilities-slider__slider-dot" data-aos="ki-fade-up-small" data-aos-delay="${(i + 1) * 100}">
            <div class="facilities-tab__wrapper">
                <span class="facilities-tab__text">${ $(slider.$slides[i]).find('.facilities-slide__tab').text() }</span>
                <span class="facilities-tab__text-bold" aria-hidden="true">${ $(slider.$slides[i]).find('.facilities-slide__tab').text() }</span>
            </div>
            <span class="visually-hidden">Slide ${i}</span>
            </button>`;
        },
    });

    //MOBILE DROPDOWN
    let mobileDropdown = document.querySelector('.home-facilities-slider__dropdown-list')
    let mobileDots = document.querySelector('.home-facilities-slider__dropdown-dots-source .home-facilities-slider__slider-dots')
    if (mobileDropdown && mobileDots){
        mobileDropdown.appendChild(mobileDots)
    }

    $('.home-facilities-slider__slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
        slick.$slides[currentSlide].querySelector('.facilities-slide').classList.add('slide-out');
        slick.$slides[nextSlide].querySelector('.facilities-slide').classList.add('slide-in');
        slick.$slides[currentSlide].querySelector('.facilities-slide').classList.remove('facilities-show-content');
        // $('.home-facilities-slider__slider-dots').addClass('home-facilities-slider--animate-tab');
    });

    function updateFacilitiesDropdownToggle(){
       //update mobile dropdown
       let dropdownToggle = document.querySelector("#dropdownFacilitiesButton .dropdown-toggle__selection--facilities")
       let activeSlide = document.querySelector('.home-facilities-slider__slider .slick-active .facilities-slide__tab');
       if (dropdownToggle && activeSlide){
           dropdownToggle.innerHTML = activeSlide.innerHTML;
       }
    }

    $('.home-facilities-slider__slider').on('afterChange', function(event, slick, currentSlide, nextSlide){
        slick.$slides[currentSlide].querySelector('.facilities-slide').classList.add('facilities-show-content');
        $('.facilities-slide').each(function(){
            $(this).removeClass('slide-out');
            $(this).removeClass('slide-in');
        })
        //Update second set of pagination on mobile
        $.each(slick.$dots, (i, el) => {
            $(el).find('li').eq(currentSlide).addClass('slick-active').find('button');
        })
 
        updateFacilitiesDropdownToggle()
        // $('.home-facilities-slider__slider-dots').removeClass('home-facilities-slider--animate-tab');
    });

    //initial slide starts off with text visible
    $('.facilities-slide')[0].classList.add('facilities-show-content');
    updateFacilitiesDropdownToggle()

    //Second mobile pagination needs slick active added on the first load
    $('.home-facilities-slider__slider-controls li:first-child').addClass('slick-active');
    $('.home-facilities-slider__dropdown li:first-child').addClass('slick-active');



   
}


if ($('.home-innovation__slider')[0]){
    $('.home-innovation__slider').slick({
        speed: 500,
        fade: true,
        cssEase: 'linear',
        infinite: false,
        arrows:false,
        dots: true,
        appendDots: $('.home-innovation__tabs'),
        customPaging : function(slider, i) {
            return `<button class="home-innovation__tab" data-aos="ki-fade-up-small" data-aos-delay="${(i + 1) * 100}">${ $(slider.$slides[i]).find('.home-innovation__slide-title').text() }
            <span class="visually-hidden">Slide ${i}</span>
            </button>`;
        },
    });
    $('.home-innovation__slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
        slick.$slides[currentSlide].querySelector('.home-innovation__slide').classList.add('slide-out');
        slick.$slides[nextSlide].querySelector('.home-innovation__slide').classList.add('slide-in');
        slick.$slides[currentSlide].querySelector('.home-innovation__slide').classList.remove('home-innovation-show-content');
    });

    $('.home-innovation__slider').on('afterChange', function(event, slick, currentSlide, nextSlide){
        slick.$slides[currentSlide].querySelector('.home-innovation__slide').classList.add('home-innovation-show-content');
        $('.home-innovation__slide').each(function(){
            $(this).removeClass('slide-out');
            $(this).removeClass('slide-in');
        })
    });

    //initial slide starts off with text visible
    $('.home-innovation__slide')[0].classList.add('home-innovation-show-content');
}

if ($('.home-projects__projects-slider')[0]){
    $('.home-projects__projects-slider').slick({
        slidesToShow:3,
        speed: 500,
        cssEase: 'linear',
        infinite: false,
        arrows:true,
        nextArrow: $('.home-projects__controls-right'),
        prevArrow: $('.home-projects__controls-left'),
        dots: true,
        appendDots: $('.home-projects__dots'),
        customPaging : function(slider, i) {
            return `<button class="home-projects__dot" data-aos="ki-fade-up-small" data-aos-delay="${(i + 1) * 100}"><span class="visually-hidden">Slide ${i}</span></button>`;
        },
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true
                }
            }
        ]
    });
    if (document.querySelectorAll('.home-projects__projects-slide').length > 3){
        $('.home-projects__projects-slider').addClass('slick-slider--overflow');
    }
}


//Slider buttons
//This is purely to correct aesthetic issues with buttons staying focused and animation not triggering
//Only happens on mouse focus to not negatively affect accessibility

let homepage_body = document.querySelector('body');
let homepage_sliderBtns = document.querySelectorAll('.home-slider-button');

if (homepage_sliderBtns.length > 0){
    homepage_sliderBtns.forEach( btn => {
        btn.addEventListener('click', function(){
            setTimeout(() => {
                if (homepage_body.classList.contains('using-mouse')){
                    btn.blur();
                }
            }, 0);
        })
    })
}


//Homepage facilities button, duplicated to appear in different spot on mobile
let homepageFacilitiesButton = document.querySelector('.home-facilities-button');
let homepageFacilitiesSliderSection = document.querySelector('.home-facilities__mobile-button');

if (homepageFacilitiesButton && homepageFacilitiesSliderSection){
    let homepageClonedButton = homepageFacilitiesButton.cloneNode(true);
    homepageFacilitiesSliderSection.insertAdjacentElement('beforeend', homepageClonedButton)
}


let homepageServicesButton = document.querySelector('.row-services__button');
let homepageServicesSection = document.querySelector('.row-services-slider__primary');

if (homepageServicesButton && homepageServicesSection){
    let homepageServicesClonedButton = homepageServicesButton.cloneNode(true);
    homepageServicesSection.insertAdjacentElement('beforeend', homepageServicesClonedButton)
}


// $('.sidebar-panel-project-photos__container').slick({
//     infinite: false,
//     slidesToShow: 4,
// })



/* ------------------------------- */
/* AOS */
/* ------------------------------- */
AOS.init({
    // Global settings:
    disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
    startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
    initClassName: 'aos-init', // class applied after initialization
    animatedClassName: 'aos-animate', // class applied on animation
    useClassNames: true, // if true, will add content of `data-aos` as classes on scroll
    disableMutationObserver: false, // disables automatic mutations' detections (advanced)
    debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
    throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)


    // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
    offset: (window.innerHeight * .1), // offset from the original trigger point
    delay: 100, // values from 0 to 3000, with step 50ms
    duration: 400, // values from 0 to 3000, with step 50ms
    easing: 'ease', // default easing for AOS animations
    once: true, // whether animation should happen only once - while scrolling down
    mirror: false, // whether elements should animate out while scrolling past them
    anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
});



/* ------------------------------- */
/* COUNTUP */
/* ------------------------------- */


const countUpOptions = {
    suffix: '+',
    enableScrollSpy: true,
    scrollSpyOnce: true
}


var industryExpertsNum = document.getElementById('countup-industryexperts');
if(industryExpertsNum){
    var industryExpertsCountUp = new CountUp('countup-industryexperts', industryExpertsNum.innerText, countUpOptions);
}
var servicesNum = document.getElementById('countup-services');
if(servicesNum){
    var servicesCountUp = new CountUp('countup-services', servicesNum.innerText, countUpOptions);
}
var facilitiesNum = document.getElementById('countup-facilities');
if(facilitiesNum){
    var facilitiesCountUp = new CountUp('countup-facilities', facilitiesNum.innerText, countUpOptions);
}




/* ------------------------------- */
/* -------- SCROLL TO TOP -------- */
/* ------------------------------- */

//window.onscroll = function() {scrollToTopFunction()};

    $('#js-scroll-to-top').on('click', function (e) {
        e.preventDefault();
        $('html,body').animate({
            scrollTop: 0
        }, 700);
    });


    $(".js-scroll-to").click(function (){
        var scrollTarget = $(this).attr('scroll-to');
        $('html, body').animate({
            scrollTop: $('#'+scrollTarget).offset().top - 150
        }, 1000);
    });


/* ----------------------------------- */
/* -------- HOMEPAGE PARALLAX -------- */
/* ----------------------------------- */


    var homepageParallax = document.querySelector('.home-diversity__bg-image');
    var homepageParallaxMobile = document.querySelector('.home-diversity__bg-image-mobile');

    if (homepageParallax){
        new simpleParallax(homepageParallax, {
            scale:1.5
        });
    }

    if (homepageParallaxMobile){
        new simpleParallax(homepageParallaxMobile, {
            scale:1.5
        });
    }
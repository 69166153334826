//******************** */
// * Media Search * 
//****************** */
/* 
    This runs on the media center and all media type indexes

    1) On page load, get any url params 
    2) Update media state object with params
    3) Update state with the type (mediaIndex, eventIndex, etc)
    2) then update the filters (dropdowns and search bar) based on state
    3) then ajax grab the media, filtered by state (limit to 4 if mediaIndex)
    4) watch the page filters for user interaction
    4) when the user interacts with a filter
        4.1) update state
        4.2) update the url params based on state
        4.3) re-grab media, filtered by state
    5) when the user clicks 'reset filters', state gets reset except for search query

    The media filtration happens on a hidden page (/views/hidden/search-ajax-request-media) 
    Whenever media are grabbed an ajax request is made to get the contents of that page
    (craft queries handled on separate page so the project index doesn't need to reload to run the queries)
    The hidden page accepts url params and uses those to filter the query it makes

    
    -------------------- STATE --------------------
    - the source of truth
    */

    let media__year = '';
    if (document.querySelector('.year-timeline')){
        // media__year = new Date().getFullYear();
        //instead of taking the current year, which may have no results, take the closest available year
        let media__closestYear = document.querySelector('.year-timeline__year:not(.year-timeline__year--disabled)');
        if (media__closestYear){
            media__year = media__closestYear.querySelector('.year-timeline__year-btn').value ?? ''; 
        }
    }

    let media__state = {
        query:'',
        solution:'',
        capability:'',
        theme:'',
        region:'',
        innovation:'',
        downloadType:'',    
        year: media__year 
    }
   
    /*
    -------------------- FUNCTIONS ------------------

    media__initialLoad()
    - get params
    - updateState(stateChange, 'params')

    media__updateState(stateChange, source)
    - update the state key/value 
        - stateChange is an object with just the changing keys, so you can update multiple in one go
        - e.g. stateChange = { theme:"Innovation", region:"Canada"}
    - source helps us determine what needs (and doesnt need) to be updated
    - if source === reset, return all values to null except query 
    - if source !== params, update url params based on this change
    - if source !== filters, call updateFilters() 
    - call updateResults()

    media__updateFilters()
    - reset all filters
    - update filters based off state 

    media__resetFilters()
    - resets dom filters to defaults

    media__updateResults()
    - send an ajax query with the url param
    - get results
    - update DOM with results 

    media__watchFilters()
    - find all filters, add eventListeners for click and keypress
    - on interaction, call updateState(stateChange, 'filters')

    media__watchTimeline()
    media__updateTimeline()

*/

function media__initalLoad(){

    const urlParams = new URLSearchParams(window.location.search);
    const queryParam = urlParams.get('query')
    const themeParam = urlParams.get('theme')
    const regionParam = urlParams.get('region')
    const solutionParam = urlParams.get('solution')
    const capabilityParam = urlParams.get('capability')
    const innovationParam = urlParams.get('innovation')
    const yearParam = urlParams.get('year')
    const downloadTypeParam = urlParams.get('downloadType')

    let paramObject = {}

    if (queryParam){ paramObject['query'] = queryParam }
    if (themeParam){ paramObject['theme'] = themeParam.toLowerCase() }
    if (regionParam){ paramObject['region'] = regionParam.toLowerCase() }
    if (solutionParam){ paramObject['solution'] = solutionParam.toLowerCase() }
    if (capabilityParam){ paramObject['capability'] = capabilityParam.toLowerCase() }
    if (innovationParam){ paramObject['innovation'] = innovationParam.toLowerCase() }
    if (yearParam){ paramObject['year'] = yearParam }
    if (downloadTypeParam){ paramObject['downloadType'] = downloadTypeParam }

    media__updateState(paramObject)
}


function media__updateState(stateChange, source){
    //Update the state with the new changes (or reset them)
    /* stateChange is an object that contains the change, e.g. : 
        { capability : 'test' }
    */

    if (source === "reset"){
        media__state['solution'] = ''
        media__state['capability'] = ''
        media__state['theme'] = ''
        media__state['region'] = ''
        media__state['innovation'] = ''
        media__state['downloadType'] = ''
        media__state['year'] = media__year
    } else {
        if (stateChange !== null && typeof stateChange === 'object'){
            Object.entries(media__state).forEach(([keyA, valueA]) => {
                Object.entries(stateChange).forEach(([keyB, valueB]) => {
                    if (keyA === keyB){
                        if (valueB === null){
                            media__state[keyA] = ''
                        } else {
                            media__state[keyA] = valueB
                        }
                    }
                })
            });
        }
    }
    
    if (source === "reset"){
        media__resetFilters()
    }
    if (source !== "params"){
        let urlParams = new URLSearchParams('');
        let paramsAreEmpty = true;
        Object.entries(media__state).forEach(([key, value]) => {
            if (value !== ''){
                urlParams.set(key, value)
                paramsAreEmpty = false;
            }
        })
        let showParamMark = paramsAreEmpty ? '' : '?'
        window.history.replaceState({}, '', `${window.location.pathname}${showParamMark}${urlParams}`);
    }
    media__updateFilters()
    media__updateTimeline()
    media__updateResults()
}


function media__resetFilters(){
    // Unhighlight any active dropdown items
    let activeDropdownItems = document.querySelectorAll('.dropdown-item--active');
    activeDropdownItems.forEach(item => {
        item.classList.remove('dropdown-item--active');
    })

    // Highlight the default "all __" option again
    document.querySelector('.all-region').classList.add('dropdown-item--active');
    document.querySelector('.all-theme').classList.add('dropdown-item--active');
    document.querySelector('.all-solution').classList.add('dropdown-item--active');
    document.querySelector('.all-capability').classList.add('dropdown-item--active');
    document.querySelector('.all-innovation').classList.add('dropdown-item--active');

    // Change all dropdown inner text to "All __"
    document.querySelector('.dropdown-toggle__selection--region').innerText = "Global";
    document.querySelector('.dropdown-toggle__selection--theme').innerText = "All Themes";
    document.querySelector('.dropdown-toggle__selection--solution').innerText = "All Solutions";
    document.querySelector('.dropdown-toggle__selection--capability').innerText = "All Capabilities";
    document.querySelector('.dropdown-toggle__selection--innovation').innerText = "All Innovations";

    //Download Types, if relevant
    let downloadTypes = document.querySelectorAll(".download-type-button");
    let downloadAccessibilityPrompt = document.querySelector('.download-current-type');

    if (downloadTypes){
        downloadTypes.forEach( type => {
            type.classList.remove("download-type-button--active")
        })
        let allDownloads =  document.querySelector(".download-type-button[data-value='allDownloads']");
        if (allDownloads) {
            allDownloads.classList.add("download-type-button--active")
        }
        if (downloadAccessibilityPrompt){
            downloadAccessibilityPrompt.innerHTML = `Currently no active filter, showing all results`;
        }
    } 
}


function media__updateFilters(){
    media__resetFilters()
    //Loop through state and update the filter dropdowns of any active types
    Object.entries(media__state).forEach(([key, value]) => {
        if (key === 'region' || key === 'theme' || key === 'capability' || key === 'solution' || key === 'innovation'){
            if (value !== ''){
                let filter = document.querySelector(`.${key}-option[data-${key}=${value}]`)
                if (filter){
                    document.querySelector(`.all-${key}`).classList.remove('dropdown-item--active'); //first remove the all filter
                    filter.classList.add('dropdown-item--active')
                    let filterTitle = filter.querySelector('span').innerHTML;
                    document.querySelector(`.dropdown-toggle__selection--${key}`).innerHTML = filterTitle;
                } 
            }
            else if (value === ''){
                // Add active class to "all __" option when it is clicked (i.e. no filter)
                document.querySelector(`.all-${key}`).classList.add('dropdown-item--active');
            }
        }
        if (key === 'query' && value !== ''){
            document.querySelector('#media-search').value = value;
        }
        if (key === 'downloadType' && value !== ''){
            let downloadTypes = document.querySelectorAll(".download-type-button");
            if (downloadTypes){
                downloadTypes.forEach( type => {
                    type.classList.remove("download-type-button--active")
                })
            } 
            let downloadInput = document.querySelector(`.download-type-button[data-value="${value}"`);
            let downloadInnerText = downloadInput.querySelector('.download-type-button--inner-text');
            let downloadAccessibilityPrompt = document.querySelector('.download-current-type');
            if (downloadInput){
                downloadInput.classList.add("download-type-button--active");
                if (downloadAccessibilityPrompt && downloadInnerText){
                    downloadAccessibilityPrompt.innerHTML = `Currently filtering by ${downloadInnerText.innerHTML}`;
                }
            }
        }
    })
}


function media__watchFilters(){

    ['region', 'theme', 'capability', 'solution', 'innovation'].forEach(( type ) => {
        let filters = document.querySelectorAll(`.${type}-option`);
        if (filters){
            filters.forEach((filter) => {
                filter.addEventListener('click', function(e){
                    if (type === 'capability' || type === 'solution'){
                        if(filter.classList.contains('is-open') || filter.classList.contains('is-open--nested')) {
                            return filter;
                        }
                    }
                    let result = {}
                    result[type] = e.currentTarget.getAttribute(`data-${type}`);
                    media__updateState(result, 'filters')
                })
                filter.addEventListener('keypress', function(e){
                    if(e.key === "Enter") {
                        let result = {}
                        result[type] = e.currentTarget.getAttribute(`data-${type}`);
                        media__updateState(result, 'filters')
                    }
                })
            })
        }
    })

    let downloadTypes = document.querySelectorAll('.download-type-button');
    if (downloadTypes) {
        downloadTypes.forEach ( button => {
            button.addEventListener("click", function(e){
                let result = {};
                let buttonType = e.currentTarget.getAttribute("data-value");
                downloadTypes.forEach( btn => {
                    if (btn.getAttribute("data-value") !== buttonType){
                        btn.classList.remove('download-type-button--active');
                    } else {
                        btn.classList.add('download-type-button--active');
                    }
                })
                result['downloadType'] = buttonType;
                media__updateState(result);

            })
        })
    }

    //Watch the search box 
    $('#media-search').keyup(_.debounce(function(event){
        let result = { query : event.target.value } 
        media__updateState(result)
    } , 500)); 
}


function media__watchTimeline(){
    let years = document.querySelectorAll('.year-timeline__year-btn');
    if ( years ){
        years.forEach( year => {
            year.addEventListener('change', function(e){
                let result = { year : e.target.value }
                media__updateState(result)
            })
        })
    }
}

function media__updateTimeline(){
    let years = document.querySelectorAll('.year-timeline__year-btn');
    if ( years ){
        years.forEach( year => {
            if ( media__state['year'] === year.value ){
                year.checked = true;
            }
        })
    }
}


function media__updateResults(){

    let urlParam = window.location.search;

    //Show or hide the featured media
    if (urlParam !== '' && urlParam !== '?') {
        $('.media-center__featured-media').addClass('media-center__featured-media--hide');
    } else {
        $('.media-center__featured-media').removeClass('media-center__featured-media--hide');
    }

    //Get the page type
    let pageType = null;
    let pageTypeUrl = window.location.pathname; 
    // console.log("pageTypeUrl ", pageTypeUrl)

    //Remove a trailing slash if there is one 
    if (pageTypeUrl.endsWith('/')){
        pageTypeUrl = pageTypeUrl.slice(0, -1)
    }

    if (pageTypeUrl === '/media-center') pageType = "mediaIndex"
    if (pageTypeUrl === '/media-center/news-releases') pageType = "newsReleases"
    if (pageTypeUrl === '/media-center/events') pageType = "events"
    if (pageTypeUrl === '/media-center/videos') pageType = "videos"
    if (pageTypeUrl === '/media-center/literature') pageType = "downloads"
    if (pageTypeUrl === '/media-center/technical-articles') pageType = "technicalArticles"

    //Add the page type
    if (urlParam === '?' || urlParam === '' || urlParam === null){
        urlParam = `?type=${pageType}`
    } else {
        urlParam = urlParam + `&type=${pageType}`
    }

    //Add the limiter
    // if (pageType !== 'mediaIndex'){
    //     urlParam = urlParam + `&limit=1`
    // }

    // console.log("urlParam ", urlParam)

    let searchString = `/views/hidden/search-ajax-request-media${urlParam}`;
    $('.media-loading').fadeIn();
    $('#query-container-media').fadeOut();

    $.ajax(searchString, {
        dataType : 'html',
        success : function (response) {
            $('.media-loading').fadeOut();
            var $html = $('<div />').append(response);
            $('#query-container-media').html($html.find('.js-search-results--media').html()).fadeIn();
            $('#query-container-media').fadeIn();
            // console.log("success, content loads in")

            // Update media type "View all" links with new params every time results load in
            let mediaUrlParam;
            if(urlParam === `?type=${pageType}`) {
                mediaUrlParam = urlParam.replace(`?type=${pageType}`, '')
            } else {
                mediaUrlParam = urlParam.replace(`&type=${pageType}`, '')
            }
            updateMediaURLs(mediaUrlParam);

            //update timeline, if applicable
            let mediaTimeline = document.querySelector('.year-timeline');
            if (mediaTimeline){
                if (window.allEnabledPostYears){
                    //variable made public by ajax-request content
                    let enabledYears = window.allEnabledPostYears;
                    if (typeof enabledYears === 'object'){
                        let timelineYearBtns = document.querySelectorAll('.year-timeline__year-btn');
                        timelineYearBtns.forEach( year => {
                            if (!enabledYears.includes(year.value)){
                                year.disabled = true;
                                year.closest('.year-timeline__year').classList.add('year-timeline__year--disabled');
                            } else {
                                year.disabled = false;
                                year.closest('.year-timeline__year').classList.remove('year-timeline__year--disabled');
                            }
                        })
                        let noResults = document.querySelector('.search-results__no-result');
                        if (noResults && enabledYears.length > 0){
                            //showing no results in the selected year, but other years are available. Switch to first one.
                            let media__closestYear = document.querySelector('.year-timeline__year:not(.year-timeline__year--disabled)');
                            if (media__closestYear){
                                media__year = media__closestYear.querySelector('.year-timeline__year-btn').value ?? ''; 
                                media__updateState({'year': media__year});
                            }
                        }
                    }
                    
                }
            }

        }
    });
}


// ------------ Initialize --------------------------

if (document.querySelector('#media-search')){ 

    // console.log("🔎 Running the Media Search")
    media__initalLoad()
    media__watchFilters()
    media__watchTimeline()

    const resetFiltersBtn = document.querySelector('.reset-all-filters__button');
    if(resetFiltersBtn){
        resetFiltersBtn.addEventListener('click', () => {
            media__updateState(null, 'reset')
        })
    }
}




// Add updated params to each media type "view all" links
function updateMediaURLs(mediaUrlParam) {
    const newsReleasesURL = document.querySelector('.view-all-media--news-releases');
    const eventsURL = document.querySelector('.view-all-media--events');
    const videosURL = document.querySelector('.view-all-media--videos');
    const technicalArticlesURL = document.querySelector(".view-all-media--technical-articles");
    const downloadsURL = document.querySelector(".view-all-media--downloads");

    if(newsReleasesURL) {
        newsReleasesURL.href = `/media-center/news-releases${mediaUrlParam}`
    }
    if(eventsURL) {
        eventsURL.href = `/media-center/events${mediaUrlParam}`
    }
    if(videosURL) {
        videosURL.href = `/media-center/videos${mediaUrlParam}`
    }
    if(technicalArticlesURL) {
        technicalArticlesURL.href = `/media-center/technical-articles${mediaUrlParam}`
    }
    if(downloadsURL) {
        downloadsURL.href = `/media-center/downloads${mediaUrlParam}`
    }
}
